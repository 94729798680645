import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom"

import './assets/common.css'

import VideoPage from './pages/video'
import LmtPage from './pages/lmt'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="lmt" />}></Route>
        <Route path="video" element={<VideoPage />}></Route>
        <Route path="lmt" element={<LmtPage />}></Route>
      </Routes>
    </Router>
  )
}

export default App
