/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useLocation } from 'react-router'
import videojs from 'video.js'

import 'video.js/dist/video-js.min.css'
import './video.css'

const VideoPage = () => {
  const video = React.useRef(null)
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  React.useEffect(
    () => {
      const {
        top: topFrame
      } = window
      const player = videojs(
        video.current,
        {
          autoplay: true,
          controls: true,
          playsInline: true,
          preload: 'metadata',
          sources: [{
            src: decodeURIComponent(params.get('url')),
            type: 'application/x-mpegURL'
          }]
        },
        () => {
          console.log('video ready.')
          player.play()
          setTimeout(() => player.pause(), 300)

          player.on('pause', () => topFrame.postMessage(false, '*'))
          player.on('play', () => topFrame.postMessage(true, '*'))
          player.on('ended', () => topFrame.postMessage(false, '*'))
        }
      )

      const handleMessage = e => {
        console.log('new message:', e)
        if (e.data) {
          player.play()
        } else {
          player.pause()
        }
      }
      window.addEventListener('message', handleMessage)
      return () => {
        window.removeEventListener('message', handleMessage)
      }
    },
    [params]
  )

  return (
    <video
      ref={video}
      className="video video-js"
      autoPlay
      playsInline
    ></video>
  )
}

export default VideoPage
